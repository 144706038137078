export const owsProjectionsOverride = (players, useJM) => {
  if (useJM) {
    return players.map(p => {
      return {
        ...p,
        'ProjPts': p.JMProjPts
      }
    })
  } else {
    return players.map(p => {
      return {
        ...p,
        'ProjPts': p.HouseProjPts
      }
    })
  }
}