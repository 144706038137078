export const framed = true
export const partner = 'ows'
export const overridePerms = true
export const authed = false
export const customPresets = true

// CSS Overrides
export const overridePrimary = '#FF8615'
export const overrideSecondary = '#E5DACB'
export const overrideCheckboxColor = '#8ACDEC'
export const overrideButtonText = '#000'
export const overrideBackground = '#f7f1ea'
export const overrideColor = "#000000"
export const overridePlayerText = "#000"
export const overrideBackgroundSecondary = '#f7f1ea'
// export const overrideTableHighlight = "#41464f"
export const overrideTableHighlight = "rgb(230, 247, 255)"
// TEMP remove
export const api_token = 'wuOOYr1ZInN5aG6LaoRtx4eFdZCsNAH6'
export const api_id = '207859'

export const allowCheatsheets = false